
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "LandingVideo",
  data() {
    return {
      imageLink: require("@/assets/images/landing-video-img.jpeg"),
      videoLink: "https://www.youtube.com/embed/8SzPk5cKxtM?autoplay=1",
      style: `<style>
                * {
                  padding:0;
                  margin:0;
                  overflow:hidden
                }
                html,body {
                  height:100%
                }
                img {
                  position:absolute;
                  width:100%;
                  top:0;
                  bottom:0;
                  margin:auto
                }
                svg {
                  position:absolute;
                  width:4.9em;
                  height:4.9em;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  font:1rem sans-serif;
                }
                svg:hover {
                  opacity: 0.8;
                }
              </style>`
    };
  },

  computed: {
    ...mapGetters({
      item: "helper/getLandingPage"
    })
  },

  mounted() {
    this.$store.dispatch("helper/GET_LANDING_PAGE");
  }
});
