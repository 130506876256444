
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "Footer",
  props: {
    footerStyle: String
  },
  data() {
    return {
      socialLinks: [
        {
          name: "twitter",
          icon: "twitter"
        },
        {
          name: "linkedin",
          icon: "linkedin-filled"
        },
        {
          name: "facebook",
          icon: "facebook"
        },
        {
          name: "instagram",
          icon: "instagram"
        },
        {
          name: "outlook",
          icon: "mail"
        }
      ]
    };
  },
  computed: {
    /*footerStyle(): string | null {
      if (this.$route.name == "LandingPage" && this.setting.footer_color) {
        return `background:${this.setting.footer_color}`
      } else if (this.$route.name != "LandingPage" && this.setting.global_footer_color) {
        return `background:${this.setting.global_footer_color}`
      }

      return null;
    },*/
    ...mapGetters({
      version: "helper/getVersion",
      footer: "helper/getFooter",
      setting: "helper/getSetting"
    })
  },
  mounted() {
    this.$store.dispatch("helper/GET_VERSION");
    this.$store.dispatch("helper/GET_FOOTER");
  },
  methods: {
    getLink(link: string) {
      return this.footer[`${link}_link`];
    }
  }
});
