
import Vue from "vue";
import Header from "@/components/Header/Header.vue";
import Footer from "@/components/Footer.vue";
import SignUpMentor from "@/components/SignUp/SignUpMentor.vue";

export default Vue.extend({
  name: "SignUpInvitationCodePage",
  components: {
    Header,
    Footer,
    SignUpMentor
  },
  props: {
    userType: String
  }
});
