
import Vue from "vue";

export default Vue.extend({
  name: "SignUpCompleteProfile",
  components: {},
  data() {
    return {};
  }
});
