var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('iframe',{staticClass:"youtube-video",attrs:{"width":"560","height":"315","src":_vm.item.video_url ? _vm.item.video_url : _vm.videoLink,"srcdoc":`${_vm.style}
      <img src=${_vm.item.video_thumbnail || _vm.imageLink} alt='Mentorice-video'>
      <a href=${_vm.item.video_url ? _vm.item.video_url : _vm.videoLink}>
          <svg width='94' height='94' viewBox='0 0 94 94' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path opacity='0.8' d='M46.8395 91.6789C71.6036 91.6789 91.6789 71.6036 91.6789 46.8395C91.6789 22.0753 71.6036 2 46.8395 2C22.0753 2 2 22.0753 2 46.8395C2 71.6036 22.0753 91.6789 46.8395 91.6789Z' fill='#3E85D0' stroke='white' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/>
            <path d='M36.0918 21.5293L72.0833 45.7111L36.0918 69.8929V21.5293Z' fill='#4184CA' stroke='#F0F3FF' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/>
          </svg>
      </a>`,"title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})
}
var staticRenderFns = []

export { render, staticRenderFns }