
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "SignUpEmailNotRegistered",
  components: {},
  props: {
    userType: String
  },
  data() {
    return {
      data: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password_confirmation: "",
        type: this.userType
      },
      i_agree: false,
      registerBtnLoading: false,
      passwordFieldType: "password",
      socialIcons: [
        { name: "twitter", link: "", color: "#1DA1F2" },
        { name: "facebook", link: "", color: "#1DA1F2" },
        { name: "instagram", link: "", color: "#1DA1F2" }
      ]
    };
  },
  computed: {
    ...mapGetters({
      termsAndConditionLink: "helper/getTermsAndConditionLink"
    })
  },
  methods: {
    switchPasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    onRegister() {
      this.registerBtnLoading = true;
      this.$store
        .dispatch("auth/REGISTER_USER", this.data)
        .then(() => {
          this.registerBtnLoading = false;
          // @ts-ignore
          this.$alertify.success(
            (this as any).$t("setting.signUpSuccessMessage")
          );
        })
        .catch(error => {
          this.registerBtnLoading = false;
          if (error.response.status === 422) {
            for (const e of error.response.data.errors) {
              // @ts-ignore
              this.$alertify.error(e);
            }
          }
        });
    }
  }
});
