
import Vue from "vue";
import SignUpFormMentor from "@/components/SignUp/SignUpFormMentor.vue";
import SignUpInvitation from "@/components/SignUp/SignUpInvitation.vue";
import SignUpCompleteProfile from "@/components/SignUp/SignUpCompleteProfile.vue";
import SignUpInvitationCodeRequest from "@/components/SignUp/SignUpInvitationCodeRequest.vue";
import SignUpEmailNotRegistered from "@/components/SignUp/SignUpEmailNotRegistered.vue";
import LandingVideo from "@/components/LandingPage/LandingVideo.vue";

export default Vue.extend({
  name: "SignUpMentor",
  components: {
    SignUpFormMentor,
    SignUpInvitation,
    SignUpCompleteProfile,
    SignUpInvitationCodeRequest,
    SignUpEmailNotRegistered,
    LandingVideo
  },
  props: {
    userType: String
  },
  data() {
    return {
      activeComponent: {
        invitation: "SignUpInvitation",
        form: "SignUpFormMentor",
        "invitation-code-request": "SignUpInvitationCodeRequest",
        "email-not-registered": "SignUpEmailNotRegistered"
      },
      isPlay: false,
      videoId: "tnpXX1Xp1vM"
    };
  },
  computed: {
    player() {
      //@ts-ignore
      return this.$refs.youtube.player;
    }
  },
  methods: {
    playVideo() {
      //@ts-ignore
      this.isPlay = true;
      //@ts-ignore
      this.player.playVideo();
    },
    playing() {
      console.log(" we are watching!!!");
    }
  }
});
